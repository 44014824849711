@use "sass:list";
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/mixins/_breakpoints";

.min-height-0 {
  min-height: 0 !important;
}

.position-sticky {
  position: sticky;
  top: 0px;
  z-index: 1000;
}

.sticky-top {
  z-index: 100 !important;
}

.w-33 {
  width: 33% !important;
}

.mw-33 {
  max-width: 33% !important;
}

.mw-50 {
  max-width: 50%;
}

.mw-75 {
  max-width: 75% !important;
}

$breakpoints: "sm", "md", "lg", "xl";
$percentages: 25, 33, 50, 66, 75, 100;

@each $breakpoint in $breakpoints {
  @include media-breakpoint-up(#{$breakpoint}) {
    .overflow-#{$breakpoint}-hidden {
      overflow: hidden;
    }
  }
}

@each $breakpoint in $breakpoints {
  @include media-breakpoint-up(#{$breakpoint}) {
    @each $percentage in $percentages {
      .w-#{$breakpoint}-#{$percentage} {
        width: #{$percentage}#{"%"} !important;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .white-space-sm-normal {
    white-space: normal !important;
  }

  .mw-sm-25 {
    max-width: 25% !important;
  }
}

@include media-breakpoint-up(md) {
  .w-md-auto {
    width: auto !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mw-md-33 {
    max-width: 25% !important;
  }

  .overflow-md-auto {
    overflow: auto !important;
  }

  .sticky-md-top {
    position: sticky;
    top: 0;
  }
}

@include media-breakpoint-up(lg) {
  .flex-lg-grow-0 {
    flex-grow: 0;
  }

  .mw-lg-25 {
    max-width: 25% !important;
  }
}

$base-spacer: 1rem;
$spacers: (
  0: 0,
  1: $base-spacer * .25,
  2: $base-spacer * .5,
  3: $base-spacer,
  4: $base-spacer * 1.5,
  5: $base-spacer * 3,
);
@each $size, $spacer in $spacers {
  .gap-#{$size} {
    gap: $spacer;
    .flex-even {
      flex: 1;
    }
    @for $i from 1 through 8 {
      $number-of-gaps: calc(#{$i} - 1);
      $total-gap-width: calc(#{$spacer} * #{$number-of-gaps});
      .flex-even:first-child:nth-last-child( #{$i} ),
      .flex-even:first-child:nth-last-child( #{$i} ) ~ .flex-even {
        min-width: calc((100% - #{$total-gap-width}) / #{$i});
      }
    }
  }
  @each $breakpoint in $breakpoints {
    @include media-breakpoint-up($breakpoint) {
      .gap-#{$breakpoint}-#{$size} {
        gap: $spacer;
      }
    }
  }
}

@each $breakpoint in $breakpoints {
  @each $percentage in $percentages {
    @include media-breakpoint-up($breakpoint) {
      .h-#{$breakpoint}-#{$percentage} {
        height: $percentage * 1% !important;
      }
    }
  }
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.h-40 {
  height: 40% !important;
}

.white-space-pre-line {
  white-space: pre-line;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
}

.object-fit-contain {
  // polyfill for class of same name in bootstrap 5
  object-fit: contain !important;
}

@each $size, $spacer in $spacers {
  @each $breakpoint in $breakpoints {
    @include media-breakpoint-up($breakpoint) {
      .column-gap-#{$breakpoint}-#{$size} {
        column-gap: $spacer !important;
      }
    }
  }
  .column-gap-#{$size} {
    column-gap: $spacer !important;
  }
}

.d-grid {
  display: grid;
}

@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }

  .grid-cols-span-#{$i} {
    grid-column: span $i;
  }

  @each $breakpoint in $breakpoints {
    @include media-breakpoint-up($breakpoint) {
      .grid-cols-#{$breakpoint}-#{$i} {
        grid-template-columns: repeat($i, minmax(0, 1fr)) !important;
      }
  
      .grid-cols-span-#{$breakpoint}-#{$i} {
        grid-column: span $i !important;
      }
    }
  }
}

@each $breakpoint in $breakpoints {
  @include media-breakpoint-up($breakpoint) {
    .d-#{$breakpoint}-grid {
      display: grid !important;
    }
  }
}

.min-h-0 {
  min-height: 0;
}

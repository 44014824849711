body {
  top: 0px !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

#google_translate_element {
  display: none;
}

#google_translate_element a {
  display: none;
}

.goog-tooltip {
  display: none !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  box-sizing: none !important;
}

.goog-te-banner {
  display: none !important;
}

iframe.goog-te-banner-frame {
  display: none !important;
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  margin-top: 0.5cm;
}

#goog-gt-tt {
  display: none !important;
}

#goog-gt- {
  display: none !important;
}

.skipTranslate {
  display: none;
}

.skiptranslate {
  display: none;
}

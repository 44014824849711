/* width */
$scrollbar-width: 15px;
::-webkit-scrollbar {
  width: $scrollbar-width;
  background: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--tiaBorder);
  border-radius: 50px;
  border: 5px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  opacity: 0.5;
}

.insig-scrollbar-padding-left {
  padding-left: $scrollbar-width;
}

.insig-scrollbar-padding-right {
  padding-right: $scrollbar-width;
}

@use './insig-health-colors';

/* Global Styles */

* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label {
  margin: 0;
}

button {
  border: none;
  background: none;
}

button:focus,
button:active {
  outline: 2px solid;
  outline-offset: -2px;
}

body {
  background-color: var(--tiaBackground);
}

.main {
  min-height: 80vh;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed !important;
}

a {
  text-decoration: underline;
  color: var(--tiaPrimary);
  cursor: pointer;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.d-flex:not(.flex-column) > hr {
  margin: 1rem;
}

@use 'insig-health-colors';

:root {
  body {
    color: var(--tiaPrimaryText);
    font-size: 14px;

    h1, .h1 {
      font-size: calc(22px + 0.25vw);
      font-weight: 600;
      margin-bottom: 0;
      padding-top: 20px;
    }
    
    h2, .h2 {
      font-size: 16px;
      font-weight: 700;
    }
    
    h3, .h3 {
      font-size: 14px;
      font-weight: 500;
    }
  
    [role="doc-subtitle"] {
      color: var(--tiaSecondaryText);
      font-size: calc(14px + 0.25vw);
      font-weight: 600;
    }
  }
}

/* responsive font sizes */
.font-size-xs {
  font-size: calc(10px + 0.25vw);
}

.font-size-sm {
  font-size: calc(12px + 0.25vw);
}

.font-size-md {
  font-size: calc(14px + 0.25vw);
}

.font-size-lg {
  font-size: calc(16px + 0.25vw);
}

.font-size-xl {
  font-size: calc(18px + 0.25vw);
}

.font-size-xxl {
  font-size: calc(18px + 0.5vw);
}

.lightText { /* deprecates global styles on <p> but should be more expressive */
  color: var(--tiaSecondaryText);
  font-size: 14px;
  font-weight: 500;
}

.bold {
  font-weight: 600 !important;
}

.textGreen {
  color: var(--tiaGreen);
}

.textBlue {
  color: var(--tiaPrimary);
}

.textOrange {
  color: var(--tiaOrange);
}

.textPink {
  color: var(--tiaPink);
}

.textGray {
  color: var(--tiaGray);
}

.textMediumGray {
  color: var(--tiaMediumGray);
}

.textDark {
  color: var(--tiaDarkGray);
}

.textLight {
  color: var(--tiaLightGray);
}

.textPrimary {
  color: var(--tiaPrimaryText);
}

.textSecondary {
  color: var(--tiaSecondaryText);
}

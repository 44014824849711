.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0) !important;
}

.mat-dialog-actions {
  min-height: auto !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.mat-dialog-container {
  border-radius: 10px !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
  margin: 20px 24px !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  font-size: calc(22px + 0.25vw) !important;
  font-weight: 600 !important;
  font-family: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
  padding-top: 20px !important;
  padding-bottom: 0 !important;
}

.mdc-dialog__title::before {
  display: none !important;
}

.mdc-dialog__actions {
  padding: 0px 24px 20px 24px !important;
}

.mdc-list-item__primary-text {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

:root {
  --themeHeaderColor: white;

  --themeHeaderStepperStepBubbleColor: var(--tiaBackground);
  --themeHeaderStepperStepLineColor: var(--tiaBackground);
  --themeHeaderStepperActiveStepColor: var(--tiaDarkGray);
  --themeHeaderStepperCompletedStepColor: var(--tiaLightGray);
  --themeHeaderStepperCompletedStepBubbleBorderColor: white;

  --themeHeaderLanguageSelectorIconFilter: none;
  --themeHeaderLanguageSelectorLabelColor: var(--tiaSecondaryText);
}

@import 'extra-bootstrap-styles';
@import 'hide-google-translate';
@import 'custom-angular-material-styles';

@import 'insig-health-globals';

@import 'insig-health-colors';
@import 'insig-health-typography';
@import 'insig-health-aria';
@import 'insig-health-scrollbar';
@import 'insig-health-container';

@import 'theme-variables';

insig-booking-app-root {
  router-outlet + * {
    display: block;
    height: 100%;
  }
}

@media(max-width: 768px) {
  .mat-expansion-panel-content .mat-expansion-panel-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button {
  cursor: pointer;
}

.top-mat-dialog-backdrop, .cdk-global-overlay-wrapper:has(> .top-mat-dialog) {
  z-index: 9999 !important;
}
